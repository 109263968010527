import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const firebaseConfig = {
    apiKey: "AIzaSyAFUro6dR0YXaVU-GgarX2XnsZ7qyw7li0",
    authDomain: "talalkozasokmuhely.firebaseapp.com",
    projectId: "talalkozasokmuhely",
    storageBucket: "talalkozasokmuhely.appspot.com",
    messagingSenderId: "744413772128",
    appId: "1:744413772128:web:042cbd713990b915908605",
    measurementId: "G-4GDERYY3TF"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
